'use client';

import Image from 'next/image';
import * as React from 'react';
import { cn } from '@noirproperties/ui/lib/utils';
import HenrikEspeImage from '@/images/henrik-espe.jpg';
import type { SiteLocale } from '@/i18n-config';

type TestimonialVideoProps = {
  lang: SiteLocale
  classname?: string;
};

export function TestimonialVideo(props: TestimonialVideoProps) {
  const videoRef = React.useRef<React.ElementRef<'video'>>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      setIsPlaying(true);
      videoRef.current.play().catch(() => {
        setIsPlaying(false);
      });
      videoRef.current.muted = false;
    }
  };

  const handleStopVideo = () => {
    if (videoRef.current) {
      setIsPlaying(false);
      videoRef.current.pause();
      videoRef.current.muted = false;
    }
  };

  return (
    <div className={cn('group relative', props.classname)}>
      <div className="aspect-h-9 aspect-w-16 overflow-hidden rounded-lg bg-black">
        {!isPlaying && (
          <Image
            alt="Video Thumbnail"
            className="absolute inset-0 h-full w-full cursor-pointer object-cover"
            onClick={handlePlayVideo}
            src={HenrikEspeImage}
          />
        )}
        <video
          className="absolute inset-0 h-full w-full object-cover"
          controls={isPlaying}
          muted
          onClick={handleStopVideo}
          poster={HenrikEspeImage.src}
          preload="none"
          ref={videoRef}
        >
          <source
            src="https://media.noirproperties.com/videos/henrik_espe_sv_original_1080p_h264.mp4"
            type="video/mp4"
          />
          <track
            default={props.lang === 'en'}
            kind="subtitles"
            label="English"
            src="/assets/subtitles_en.vtt"
            srcLang="en"
          />
          <track
            default={props.lang === 'sv'}
            kind="subtitles"
            label="Svenska"
            src="/assets/subtitles_sv.vtt"
            srcLang="sv"
          />
        </video>
        {!isPlaying && (
          <button
            className="absolute inset-0 flex items-center justify-center bg-gunmetal bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
            onClick={handlePlayVideo}
            type="button"
          >
            <PlayIcon className="size-12 text-white" />
          </button>
        )}
      </div>
    </div>
  );
}

function PlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      className={cn(
        'fill-none stroke-current transition-colors duration-300 ease-in-out hover:fill-current',
        props.className,
      )}
      fill="none"
      height="24"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="5 3 19 12 5 21 5 3" />
    </svg>
  );
}
